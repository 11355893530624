
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        // open video popup
        $('.openvideo').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    });
});


